var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('FormField',{attrs:{"label":"Nome da Forma de Entrega"},model:{value:(_vm.form.nomeforma),callback:function ($$v) {_vm.$set(_vm.form, "nomeforma", $$v)},expression:"form.nomeforma"}}),_c('v-row',[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Valor do Pedido")]),_c('small',{staticClass:"grey--text text--darken-1"},[_vm._v(" As configurações abaixo indicam os valores mínimo e máximo para o pedido. Também é possível informar até que distância em linha reta o valor mínimo se aplica e quanto de acréscimo para cada quilômetro que exceda essa distância. ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('FormField',{attrs:{"label":"Total mínimo do pedido","typeField":"number","step":"0.000001"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v("far fa-question-circle")])]}}])},[_vm._v(" Valor mínimo de um pedido para que seja aceito. Esse valor representa uma base a qual pode ser acrescido um valor de acordo com a distância de entrega. Veja as configurações de raio e acréscimo no mínimo do pedido ")])]},proxy:true}]),model:{value:(_vm.form.valorminpedido),callback:function ($$v) {_vm.$set(_vm.form, "valorminpedido", $$v)},expression:"form.valorminpedido"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('FormField',{attrs:{"label":"Total máximo do pedido","typeField":"number","step":"0.000001"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v("far fa-question-circle")])]}}])},[_vm._v(" Valor máximo de um pedido para que seja aceito ")])]},proxy:true}]),model:{value:(_vm.form.valormaxpedido),callback:function ($$v) {_vm.$set(_vm.form, "valormaxpedido", $$v)},expression:"form.valormaxpedido"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('FormField',{attrs:{"label":"Raio do valor mínimo","typeField":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v("far fa-question-circle")])]}}])},[_vm._v(" Raio em Km até onde a taxa base é aplicada sem acréscimo ")])]},proxy:true}]),model:{value:(_vm.form.distanciaPedidoMinimo),callback:function ($$v) {_vm.$set(_vm.form, "distanciaPedidoMinimo", $$v)},expression:"form.distanciaPedidoMinimo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('FormField',{attrs:{"label":"Acréscimo no pedido mínimo","typeField":"number","step":"0.000001"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v("far fa-question-circle")])]}}])},[_vm._v(" Acréscimo no pedido mínimo para cada quilômetro que exceda o raio base ")])]},proxy:true}]),model:{value:(_vm.form.acrescimoPedMinimoPorKm),callback:function ($$v) {_vm.$set(_vm.form, "acrescimoPedMinimoPorKm", $$v)},expression:"form.acrescimoPedMinimoPorKm"}})],1),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Custos de Entrega")]),_c('small',{staticClass:"grey--text text--darken-1"},[_vm._v(" As configurações abaixo indicam o valor da taxa de entrega para pedidos que utilizarem essa forma de entrega. Você pode indicar um valor inicial e até que distância, em linha reta, ele se aplica. Também é possível configurar quanto de acréscimo para cada quilômetro que exceda essa distância. ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('FormField',{attrs:{"label":"Base","typeField":"number","step":"0.000001"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v("far fa-question-circle")])]}}])},[_vm._v(" Valor base para entrega, que será adicionado ao pedido de compra do cliente para custear os procedimentos para entrega ")])]},proxy:true}]),model:{value:(_vm.form.taxaConveniencia),callback:function ($$v) {_vm.$set(_vm.form, "taxaConveniencia", $$v)},expression:"form.taxaConveniencia"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('FormField',{attrs:{"label":"Raio para base","typeField":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v("far fa-question-circle")])]}}])},[_vm._v(" Raio em Km até onde a taxa base é aplicada sem acréscimo ")])]},proxy:true}]),model:{value:(_vm.form.distanciaTaxaEntrega),callback:function ($$v) {_vm.$set(_vm.form, "distanciaTaxaEntrega", $$v)},expression:"form.distanciaTaxaEntrega"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('FormField',{attrs:{"label":"Acréscimo por KM","typeField":"number","step":"0.000001"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v("far fa-question-circle")])]}}])},[_vm._v(" Valor adicionado para cada quilômetro que exceda o raio base ")])]},proxy:true}]),model:{value:(_vm.form.taxaExtraPorMetro),callback:function ($$v) {_vm.$set(_vm.form, "taxaExtraPorMetro", $$v)},expression:"form.taxaExtraPorMetro"}})],1)],1),_c('FormSwitch',{attrs:{"label":"Permite agendamento"},on:{"click":function($event){_vm.form.idcalendarioentrega = null}},model:{value:(_vm.form.permiteAgendamento),callback:function ($$v) {_vm.$set(_vm.form, "permiteAgendamento", $$v)},expression:"form.permiteAgendamento"}}),(_vm.form.permiteAgendamento)?_c('FormSelect',{attrs:{"label":"Calendário","options":_vm.calendarios},model:{value:(_vm.form.idcalendarioentrega),callback:function ($$v) {_vm.$set(_vm.form, "idcalendarioentrega", $$v)},expression:"form.idcalendarioentrega"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }