<template>
  <v-card flat>
    <FormField label="Nome da Forma de Entrega" v-model="form.nomeforma" />
    <FormField label="Usuário" v-model="form.correios.usuario" />
    <FormField
      label="Senha"
      typeField="password"
      v-model="form.correios.senha"
      help="A senha é um token gerado através dos Correios para acesso a API. Para mais informações, consulte os nossos artigos
    sobre 'Como configurar a forma de entrega Correios? '"
    />
    <FormField
      label="Cartão de postagem"
      v-model="form.correios.cartaoPostagem"
      help="O cartão de postagem é um número que identifica o contrato entre a
    empresa e os Correios. Para mais informações, consulte os nossos artigos
    sobre 'Como configurar a forma de entrega Correios? '"
    />
    <h4>Método de cálculo</h4>
    <FormRadioGroup
      v-model="form.correios.metodoCalculo"
      inline
      :radios="metodosCalculo"
    />
    <h4>Enviar valor declarado</h4>
    <FormRadioGroup
      v-model="form.correios.valorDeclarado"
      inline
      :radios="valoresDeclarado"
    />
    <FormField
      label="Custo de separação"
      :money="form.correios.acrescimoTaxa === null ? null : moneyMask"
      v-model="form.correios.acrescimoTaxa"
    />
    <FormField
      label="Tempo de preparo e despacho em dias"
      v-model="form.correios.diasSeparacao"
      typeField="number"
    />
    <FormField
      label="CEP de origem"
      v-model="form.correios.cepOrigem"
      typeField="number"
      mask="#####-###"
    />
    <FormSelect
      label="Serviços ativados"
      v-model="form.correios.opcoes.servicos"
      :options="opcoesCorreios"
      :multiple="true"
    />
  </v-card>
</template>

<script>
import FormRadioGroup from '@/components/form/FormRadioGroup'
import FormField from '@/components/form/FormField'
import FormSelect from '@/components/form/FormSelect'
import { OPCOESCORREIOS } from '@/store/actions/formasEntrega'

export default {
  name: 'FormaEntregaFormCorreios',
  components: {
    FormSelect,
    FormRadioGroup,
    FormField
  },
  props: {
    data: {
      type: [Object],
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      moneyMask: {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: '',
        default: '',
        precision: 2,
        masked: false
      },
      form: {},
      metodosCalculo: [
        { label: 'Soma de custo por produto', value: 'SOMA_CUSTO_POR_PRODUTO' },
        {
          label: 'Cálculo de volumes combinados',
          value: 'CUSTO_CUBAGEM_COMBINADA'
        }
      ],
      valoresDeclarado: [
        { label: 'Sim', value: 'DECLARAR_VALOR' },
        { label: 'Não', value: 'NAO_DECLARAR_VALOR' }
      ],
      opcoesCorreios: OPCOESCORREIOS
    }
  },
  methods: {
    update() {
      this.$emit('update', this.form)
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form)
      }
    }
  },
  created() {
    this.form = this.data
  }
}
</script>
