<template>
  <v-card flat>
    <FormField label="Nome da Forma de Entrega" v-model="form.nomeforma" />
    <FormField label="Token da api" v-model="form.frenet.token" />
    <FormField
      label="Custo de separação"
      :money="form.frenet.acrescimoTaxa === null ? null : moneyMask"
      v-model="form.frenet.acrescimoTaxa"
    />
    <FormField
      label="Tempo de preparo e despacho em dias"
      v-model="form.frenet.diasSeparacao"
      typeField="number"
    />
    <FormField
      label="CEP de origem"
      v-model="form.frenet.cepOrigem"
      typeField="number"
      mask="#####-###"
    />
    <v-row no-gutters>
      <v-col cols="auto">
        <FormSwitch
          v-model="form.frenet.permiteRestricoesFrenet"
          label="Enviar campos para restrições da Frenet"
        />
      </v-col>
      <v-col>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
          </template>
          Quando marcado, permite que o SKU e Categoria dos produtos sejam
          enviados para a transportadora Frenet
        </v-tooltip>
      </v-col>
    </v-row>
    <h4 class="text-center">Transportadoras</h4>
    <LineDivider></LineDivider>
    <v-row>
      <v-col cols="12" md="3">
        <FormField label="Nome" v-model="transportadora.nome" />
      </v-col>
      <v-col cols="12" md="3">
        <FormField label="Código Frenet" v-model="transportadora.codigo_hub" />
      </v-col>
      <v-col cols="12" md="3">
        <FormField label="Código ERP" v-model="transportadora.codigo_erp" />
      </v-col>
      <v-col cols="12" md="3">
        <FormButton
          text="Adicionar"
          @click="addTransportadora"
          :disabled="
            !transportadora.nome ||
              !transportadora.codigo_hub ||
              !transportadora.codigo_erp
          "
        />
      </v-col>
      <v-col cols="12" v-if="form.frenet.transportadoras.length > 0">
        <v-simple-table responsive>
          <thead>
            <tr>
              <th class="text-center">Nome</th>
              <th class="text-center">Código Frenet</th>
              <th class="text-center">Código ERP</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in form.frenet.transportadoras" :key="i">
              <td class="text-center">{{ item.nome }}</td>
              <td class="text-center">{{ item.codigo_hub }}</td>
              <td class="text-center">{{ item.codigo_erp }}</td>
              <td class="text-center">
                <FormButton
                  text="Excluir"
                  type="error"
                  @click="deleteTransportadora(i)"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'

export default {
  name: 'FormaEntregaFormFrenet',
  components: {
    FormField,
    FormSwitch,
    LineDivider,
    FormButton
  },
  props: {
    data: {
      type: [Object],
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      moneyMask: {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: '',
        default: '',
        precision: 2,
        masked: false
      },
      transportadora: {},
      form: {}
    }
  },
  methods: {
    addTransportadora() {
      this.form.frenet.transportadoras.push(this.transportadora)
      this.transportadora = {}
      this.update()
    },
    deleteTransportadora(i) {
      this.form.frenet.transportadoras.splice(i, 1)
      this.update()
    },
    update() {
      this.$emit('update', this.form)
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form)
      }
    }
  },
  created() {
    this.form = this.data
  }
}
</script>
