<template>
  <v-card flat>
    <FormField label="Nome da Forma de Entrega" v-model="form.nomeforma" />
    <FormField
      label="Taxa de conveniência"
      typeField="number"
      v-model="form.taxaColeta"
      step="0.000001"
    >
      <template v-slot:append>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
          </template>
          Informe aqui a taxa que será cobrada do seu cliente para custear os
          procedimentos de separação do pedido entre outros custos
        </v-tooltip>
      </template>
    </FormField>
    <FormField
      label="Mínimo"
      typeField="number"
      v-model="form.valorminpedido_retirada"
      step="0.000001"
    >
      <template v-slot:append>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
          </template>
          Valor mínimo de um pedido para que seja aceito
        </v-tooltip>
      </template>
    </FormField>
    <FormField
      label="Máximo"
      typeField="number"
      v-model="form.valormaxpedido_retirada"
      step="0.000001"
    >
      <template v-slot:append>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
          </template>
          Valor máximo de um pedido para que seja aceito
        </v-tooltip>
      </template>
    </FormField>
    <FormSwitch
      v-model="form.permiteAgendamento"
      label="Permite agendamento"
      @change="update"
      @click="form.idcalendarioentrega = null"
    />
    <FormSelect
      v-if="form.permiteAgendamento"
      label="Calendário"
      :options="calendarios"
      v-model="form.idcalendarioentrega"
      @change="update"
    />
  </v-card>
</template>

<script>
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'

export default {
  name: 'FormaEntregaFormRetirada',
  components: {
    FormField,
    FormSwitch,
    FormSelect
  },
  props: {
    calendarios: {
      type: [Array],
      default: () => ({}),
      required: true
    },
    data: {
      type: [Object],
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      form: {}
    }
  },
  methods: {
    update() {
      this.$emit('update', this.form)
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form)
      }
    }
  },
  created() {
    this.form = this.data
  }
}
</script>
