<template>
  <v-card flat>
    <FormField label="Nome da Forma de Entrega" v-model="form.nomeforma" />
    <v-row>
      <v-col cols="12" class="mb-2">
        <h4>Valor do Pedido</h4>
        <small class="grey--text text--darken-1">
          As configurações abaixo indicam os valores mínimo e máximo para o
          pedido. Também é possível informar até que distância em linha reta o
          valor mínimo se aplica e quanto de acréscimo para cada quilômetro que
          exceda essa distância.
        </small>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Total mínimo do pedido"
          typeField="number"
          v-model="form.valorminpedido"
          step="0.000001"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
              </template>
              Valor mínimo de um pedido para que seja aceito. Esse valor
              representa uma base a qual pode ser acrescido um valor de acordo
              com a distância de entrega. Veja as configurações de raio e
              acréscimo no mínimo do pedido
            </v-tooltip>
          </template>
        </FormField>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Total máximo do pedido"
          typeField="number"
          v-model="form.valormaxpedido"
          step="0.000001"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
              </template>
              Valor máximo de um pedido para que seja aceito
            </v-tooltip>
          </template>
        </FormField>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Raio do valor mínimo"
          v-model="form.distanciaPedidoMinimo"
          typeField="number"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
              </template>
              Raio em Km até onde a taxa base é aplicada sem acréscimo
            </v-tooltip>
          </template>
        </FormField>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Acréscimo no pedido mínimo"
          typeField="number"
          v-model="form.acrescimoPedMinimoPorKm"
          step="0.000001"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
              </template>
              Acréscimo no pedido mínimo para cada quilômetro que exceda o raio
              base
            </v-tooltip>
          </template>
        </FormField>
      </v-col>
      <v-col cols="12" class="mb-2">
        <h4>Custos de Entrega</h4>
        <small class="grey--text text--darken-1">
          As configurações abaixo indicam o valor da taxa de entrega para
          pedidos que utilizarem essa forma de entrega. Você pode indicar um
          valor inicial e até que distância, em linha reta, ele se aplica.
          Também é possível configurar quanto de acréscimo para cada quilômetro
          que exceda essa distância.
        </small>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <FormField
          label="Base"
          typeField="number"
          v-model="form.taxaConveniencia"
          step="0.000001"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
              </template>
              Valor base para entrega, que será adicionado ao pedido de compra
              do cliente para custear os procedimentos para entrega
            </v-tooltip>
          </template>
        </FormField>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <FormField
          label="Raio para base"
          v-model="form.distanciaTaxaEntrega"
          typeField="number"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
              </template>
              Raio em Km até onde a taxa base é aplicada sem acréscimo
            </v-tooltip>
          </template>
        </FormField>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <FormField
          label="Acréscimo por KM"
          typeField="number"
          v-model="form.taxaExtraPorMetro"
          step="0.000001"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
              </template>
              Valor adicionado para cada quilômetro que exceda o raio base
            </v-tooltip>
          </template>
        </FormField>
      </v-col>
    </v-row>
    <FormSwitch
      v-model="form.permiteAgendamento"
      label="Permite agendamento"
      @click="form.idcalendarioentrega = null"
    />
    <FormSelect
      v-if="form.permiteAgendamento"
      label="Calendário"
      :options="calendarios"
      v-model="form.idcalendarioentrega"
    />
  </v-card>
</template>

<script>
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'

export default {
  name: 'FormaEntregaFormDelivery',
  components: {
    FormField,
    FormSwitch,
    FormSelect
  },
  props: {
    calendarios: {
      type: [Array],
      default: () => ({}),
      required: true
    },
    data: {
      type: [Object],
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      form: {}
    }
  },
  methods: {
    update() {
      this.$emit('update', this.form)
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form)
      }
    }
  },
  created() {
    this.form = this.data
  }
}
</script>
