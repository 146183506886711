var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SidePopup',{attrs:{"title":_vm.title,"loading":_vm.isLoading,"routeback":"/fornecedor/cadastro/formas-entrega"},on:{"close":_vm.onClose,"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.mode != 'simulation' && _vm.mode != 'detail')?_c('FormButton',{attrs:{"text":"Salvar","submit":"","type":"submissao"}}):(_vm.mode != 'detail')?_c('FormButton',{attrs:{"text":"Simular","type":"primario"},on:{"click":_vm.onSubmitSimulation}}):_vm._e()]},proxy:true}])},[(_vm.mode == 'detail')?_c('div',[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"nomeforma","sortDesc":false,"search":"","limitPage":[10, 25, 50, 100, 200]},scopedSlots:_vm._u([{key:"custo_transporte",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(col))+" ")]}},{key:"custo_percentual_seguro",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(col))+" ")]}},{key:"total_pedido_inicial",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(col))+" ")]}},{key:"total_pedido_final",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(col))+" ")]}}],null,false,2327839919)})],1):(_vm.mode != 'simulation')?_c('div',[_c('h4',{staticClass:"text-center"},[_vm._v("Dados básicos")]),_c('LineDivider'),(_vm.mode != 'edit')?_c('v-row',{attrs:{"justify":"center"}},[_c('FormRadioGroup',{attrs:{"inline":"","radios":_vm.formasEntregas.filter(function (f) { return f.show; })},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}})],1):_vm._e(),_c('v-window',{model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}},_vm._l((_vm.formasEntregas.filter(function (f) { return f.show; })),function(f,i){return _c('v-window-item',{key:i,attrs:{"value":f.value}},[(f.value == 'DELIVERY')?_c('FormaEntregaFormDelivery',{attrs:{"calendarios":_vm.calendarios,"data":_vm.form},on:{"update":_vm.updateValues}}):_vm._e(),(f.value == 'RETIRADA')?_c('FormaEntregaFormRetirada',{attrs:{"calendarios":_vm.calendarios,"data":_vm.form},on:{"update":_vm.updateValues}}):_vm._e(),(f.value == 'TRANSPORTE')?_c('FormaEntregaFormTransporte',{attrs:{"calendarios":_vm.calendarios,"data":_vm.form},on:{"update":_vm.updateValues}}):_vm._e(),(f.value == 'CORREIOS')?_c('FormaEntregaFormCorreios',{attrs:{"data":_vm.form},on:{"update":_vm.updateValues}}):_vm._e(),(f.value == 'FRENET')?_c('FormaEntregaFormFrenet',{attrs:{"data":_vm.form},on:{"update":_vm.updateValues}}):_vm._e(),(f.value == 'BASEADA_CLIENTE')?_c('FormaEntregaFormCliente',{attrs:{"calendarios":_vm.calendarios,"data":_vm.form},on:{"update":_vm.updateValues}}):_vm._e()],1)}),1)],1):_c('div',[_c('FormaEntregaFormSimulacao',{ref:"formSimulacao"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }