<template>
  <v-card flat>
    <FormField label="Nome da Forma de Entrega" v-model="form.nomeforma" />
    <FormSelect
      label="Segmento do cliente"
      :options="segmentos"
      v-model="form.baseadaCliente.idsegmentocliente"
    />
    <h4 class="text-center">Detalhes de transporte</h4>
    <LineDivider></LineDivider>
    <FormField
      label="Descrição da forma de transporte"
      v-model="formaTransporte.descricao_forma_transporte"
    />
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="CEP Inicial"
          v-model="formaTransporte.cep_inicial"
          typeField="number"
          mask="#####-###"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="CEP Final"
          v-model="formaTransporte.cep_final"
          typeField="number"
          mask="#####-###"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Custo transporte"
          :money="formaTransporte.custo_transporte === null ? null : moneyMask"
          v-model="formaTransporte.custo_transporte"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Custo percentual do seguro"
          v-model="formaTransporte.custo_percentual_seguro"
          :money="
            formaTransporte.custo_percentual_seguro === null ? null : moneyMask
          "
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Peso Inicial"
          v-model="formaTransporte.peso_inicial"
          typeField="number"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Peso Final"
          v-model="formaTransporte.peso_final"
          typeField="number"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Quantidade de dias de preparo"
          v-model="formaTransporte.quant_dias_preparo"
          typeField="number"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Quantidade de dias de entrega"
          v-model="formaTransporte.quant_dias_entrega"
          typeField="number"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Total inicial do pedido"
          :money="
            formaTransporte.total_pedido_inicial === null ? null : moneyMask
          "
          v-model="formaTransporte.total_pedido_inicial"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <FormField
          label="Total final do pedido"
          :money="
            formaTransporte.total_pedido_final === null ? null : moneyMask
          "
          v-model="formaTransporte.total_pedido_final"
        />
      </v-col>
      <v-col cols="12" class="text-end">
        <FormButton text="Adicionar" @click="addForma" />
      </v-col>
      <v-col cols="12" v-if="form.baseadaCliente.formas.length > 0">
        <v-simple-table responsive>
          <thead>
            <tr>
              <th class="text-center">Descrição</th>
              <th class="text-center">CEP Inicial</th>
              <th class="text-center">CEP Final</th>
              <th class="text-center">Custo Transporte</th>
              <th class="text-center">Custo % Seguro</th>
              <th class="text-center">Peso Inicial</th>
              <th class="text-center">Peso Final</th>
              <th class="text-center">Qtd dias Preparo</th>
              <th class="text-center">Qtd dias Entrega</th>
              <th class="text-center">Total Inicial</th>
              <th class="text-center">Total Final</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in form.baseadaCliente.formas" :key="i">
              <td class="text-center">{{ item.descricao_forma_transporte }}</td>
              <td class="text-center">{{ item.cep_inicial }}</td>
              <td class="text-center">{{ item.cep_final }}</td>
              <td class="text-center">{{ item.custo_transporte }}</td>
              <td class="text-center">{{ item.custo_percentual_seguro }}</td>
              <td class="text-center">{{ item.peso_inicial }}</td>
              <td class="text-center">{{ item.peso_final }}</td>
              <td class="text-center">{{ item.quant_dias_preparo }}</td>
              <td class="text-center">{{ item.quant_dias_entrega }}</td>
              <td class="text-center">{{ item.total_pedido_inicial }}</td>
              <td class="text-center">{{ item.total_pedido_final }}</td>
              <td class="text-center">
                <FormButton
                  text="Excluir"
                  type="error"
                  @click="deleteForma(i)"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <FormSwitch
      v-model="form.permiteAgendamento"
      label="Permite agendamento"
      @click="form.idcalendarioentrega = null"
    />
    <FormSelect
      v-if="form.permiteAgendamento"
      label="Calendário"
      :options="calendarios"
      v-model="form.idcalendarioentrega"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { SEGMENTO_CLIENTE_LIST } from '@/store/actions/segmento'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import FormButton from '@/components/form/FormButton'
import LineDivider from '@/components/LineDivider'

export default {
  name: 'FormaEntregaFormCliente',
  components: {
    FormSelect,
    LineDivider,
    FormField,
    FormSwitch,
    FormButton
  },
  props: {
    calendarios: {
      type: [Array],
      default: () => ({}),
      required: true
    },
    data: {
      type: [Object],
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      moneyMask: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        default: '',
        precision: 2,
        masked: false
      },
      isLoading: false,
      form: {},
      formaTransporte: {},
      segmentos: []
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId'])
  },
  methods: {
    update() {
      this.$emit('update', this.form)
    },
    getSegmentos() {
      this.isLoading = true
      this.$store
        .dispatch(SEGMENTO_CLIENTE_LIST, {
          idfornecedor: this.getFornecedorId
        })
        .then(data => {
          this.segmentos = (data || []).map(v => {
            return { value: v.idsegmentocliente, text: v.nome }
          })
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    addForma() {
      this.form.baseadaCliente.formas.push(this.formaTransporte)
      this.formaTransporte = {}
      this.update()
    },
    deleteForma(i) {
      this.form.baseadaCliente.formas.splice(i, 1)
      this.update()
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form)
      }
    }
  },
  created() {
    this.getSegmentos()
    this.form = this.data
  }
}
</script>
