<template>
  <v-card flat>
    <h4 class="text-center">Simular forma de entrega</h4>
    <LineDivider></LineDivider>
    <v-row justify="center">
      <FormRadioGroup
        v-model="simulacaoFormasEntrega.tipo"
        inline
        :radios="formasEntregas"
      />
    </v-row>
    <v-window v-model="simulacaoFormasEntrega.tipo">
      <v-window-item value="TRANSPORTE">
        <FormSelect
          label="Forma de entrega (Opcional)"
          :options="formasEntregaTransporte"
          v-model="simulacaoFormasEntrega.selecionadoTransporte"
          :clearable="true"
        />
        <FormField
          label="Cep do cliente"
          v-model="formSimulacaoTransporte.cepDestino"
          typeField="number"
          mask="#####-###"
        />
        <FormField
          label="Peso total dos produtos"
          v-model="formSimulacaoTransporte.pesoTotal"
          typeField="number"
        />
        <FormField
          label="Preço total dos produtos"
          :money="
            formSimulacaoTransporte.precoTotal === null ? null : moneyMask
          "
          v-model="formSimulacaoTransporte.precoTotal"
        />
        <v-simple-table responsive>
          <thead>
            <tr>
              <th class="text-center">Descrição</th>
              <th class="text-center">CEP Inicial</th>
              <th class="text-center">CEP Final</th>
              <th class="text-center">Custo Transporte</th>
              <th class="text-center">Peso Inicial</th>
              <th class="text-center">Peso Final</th>
              <th class="text-center">Qtd dias Preparo</th>
              <th class="text-center">Qtd dias Entrega</th>
              <th class="text-center">Total Inicial</th>
              <th class="text-center">Total Final</th>
            </tr>
          </thead>
          <tbody v-if="simulacao.length > 0">
            <tr v-for="(item, i) in simulacao" :key="i">
              <td class="text-center">{{ item.descricao_forma_transporte }}</td>
              <td class="text-center">{{ item.cep_inicial }}</td>
              <td class="text-center">{{ item.cep_final }}</td>
              <td class="text-center">
                {{ item.custo_transporte | formatMoney }}
              </td>
              <td class="text-center">{{ item.peso_inicial }} kg</td>
              <td class="text-center">{{ item.peso_final }} kg</td>
              <td class="text-center">{{ item.quant_dias_preparo }}</td>
              <td class="text-center">{{ item.quant_dias_entrega }}</td>
              <td class="text-center">
                {{ item.total_pedido_inicial | formatMoney }}
              </td>
              <td class="text-center">
                {{ item.total_pedido_final | formatMoney }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="11">
                Nenhum forma de entrega encontrada para a simulação
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-window-item>
      <v-window-item value="CORREIOS">
        <div class="text-end py-2">
          <small class="px-5">
            * A simulação dos correios suporta apenas um produto por vez
          </small>
        </div>
        <FormSelect
          label="Forma de entrega (Opcional)"
          :options="formasEntregaCorreios"
          v-model="simulacaoFormasEntrega.selecionadoCorreios"
          :clearable="true"
        />
        <FormField
          label="Cep do Cliente"
          v-model="formSimulacaoCorreios.cepDestino"
          typeField="number"
          mask="#####-###"
        />
        <FormField
          label="Altura dos produtos (m)"
          v-model="formSimulacaoCorreios.produto.altura"
          typeField="number"
        />
        <FormField
          label="Largura dos produtos (m)"
          v-model="formSimulacaoCorreios.produto.largura"
          typeField="number"
        />
        <FormField
          label="Comprimento dos produtos (m)"
          v-model="formSimulacaoCorreios.produto.comprimento"
          typeField="number"
        />
        <FormField
          label="Diâmetro dos produtos"
          v-model="formSimulacaoCorreios.produto.diametro"
          typeField="number"
        />
        <FormField
          label="Peso dos produtos (kg)"
          v-model="formSimulacaoCorreios.produto.peso"
          typeField="number"
        />
        <FormField
          label="Preço dos produtos"
          v-model="formSimulacaoCorreios.produto.preco"
          :money="
            formSimulacaoCorreios.produto.preco === null ? null : moneyMask
          "
        />
        <v-simple-table responsive>
          <thead>
            <tr>
              <th class="text-center">Descrição</th>
            </tr>
          </thead>
          <tbody v-if="simulacao.Erro">
            <tr>
              <td class="text-center" colspan="1">
                {{ simulacao.MsgErro }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="simulacao.length > 0">
            <tr v-for="(item, i) in simulacao" :key="i">
              <td class="text-center">{{ item.nome }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="1">
                Nenhum forma de entrega encontrada para a simulação
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-window-item>
      <v-window-item value="FRENET">
        <div class="text-end py-2">
          <small class="px-5">
            * A simulação dos correios suporta apenas um produto por vez
          </small>
        </div>
        <FormSelect
          label="Forma de entrega (Opcional)"
          :options="formasEntregaFrenet"
          v-model="simulacaoFormasEntrega.selecionadoFrenet"
          :clearable="true"
        />
        <FormField
          label="Cep do Cliente"
          v-model="formSimulacaoFrenet.cepDestino"
          typeField="number"
          mask="#####-###"
        />
        <FormField
          label="Altura dos produtos (m)"
          v-model="formSimulacaoFrenet.produto.altura"
          typeField="number"
        />
        <FormField
          label="Largura dos produtos (m)"
          v-model="formSimulacaoFrenet.produto.largura"
          typeField="number"
        />
        <FormField
          label="Comprimento dos produtos (m)"
          v-model="formSimulacaoFrenet.produto.comprimento"
          typeField="number"
        />
        <FormField
          label="Diâmetro dos produtos"
          v-model="formSimulacaoFrenet.produto.diametro"
          typeField="number"
        />
        <FormField
          label="Peso dos produtos (kg)"
          v-model="formSimulacaoFrenet.produto.peso"
          typeField="number"
        />
        <FormField
          label="Preço dos produtos"
          v-model="formSimulacaoFrenet.produto.preco"
          :money="formSimulacaoFrenet.produto.preco === null ? null : moneyMask"
        />
        <v-simple-table responsive>
          <thead>
            <tr>
              <th class="text-center">Descrição</th>
              <th>Observação</th>
            </tr>
          </thead>
          <tbody v-if="simulacao.length > 0">
            <tr v-for="(item, i) in simulacao" :key="i">
              <td class="text-center">{{ item.nome }}</td>
              <td>{{ item.message }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="2">
                Nenhum forma de entrega encontrada para a simulação
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-window-item>
      <v-window-item value="BASEADA_CLIENTE">
        <FormSelect
          label="Forma de entrega (Opcional)"
          :options="formasEntregaBaseadaCliente"
          v-model="simulacaoFormasEntrega.selecionadoBaseadaCliente"
          :clearable="true"
        />
        <SelectCliente
          placeholder="Pesquisar pelo nome, cpf, cnpj ou e-mail"
          label="Cliente"
          v-model="formSimulacaoBaseadaCliente.cliente"
          :endpoint="endpointClient"
          queryname="search"
          keyid="email"
          keyvalue="nome"
          :multiple="false"
          :clearable="true"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex flex-column align-start">
              <p style="margin-bottom:0!important">{{ item.nome }}</p>
              <small v-if="item.cpf"> CPF/CNPJ: {{ item.cpf }} </small>
            </div>
          </template>
        </SelectCliente>
        <FormField
          label="Cep do Cliente"
          v-model="formSimulacaoBaseadaCliente.cepDestino"
          typeField="number"
          mask="#####-###"
        />
        <FormField
          label="Peso total dos produtos"
          v-model="formSimulacaoBaseadaCliente.pesoTotal"
          typeField="number"
        />
        <FormField
          label="Preço total dos produtos"
          :money="
            formSimulacaoBaseadaCliente.precoTotal === null ? null : moneyMask
          "
          v-model="formSimulacaoBaseadaCliente.precoTotal"
        />
        <v-simple-table responsive>
          <thead>
            <tr>
              <th class="text-center">Descrição</th>
              <th class="text-center">CEP Inicial</th>
              <th class="text-center">CEP Final</th>
              <th class="text-center">Custo Transporte</th>
              <th class="text-center">Peso Inicial</th>
              <th class="text-center">Peso Final</th>
              <th class="text-center">Qtd dias Preparo</th>
              <th class="text-center">Qtd dias Entrega</th>
              <th class="text-center">Total Inicial</th>
              <th class="text-center">Total Final</th>
            </tr>
          </thead>
          <tbody v-if="simulacao.length > 0">
            <tr v-for="(item, i) in simulacao" :key="i">
              <td class="text-center">{{ item.descricao_forma_transporte }}</td>
              <td class="text-center">{{ item.cep_inicial }}</td>
              <td class="text-center">{{ item.cep_final }}</td>
              <td class="text-center">
                {{ item.custo_transporte | formatMoney }}
              </td>
              <td class="text-center">{{ item.peso_inicial }} kg</td>
              <td class="text-center">{{ item.peso_final }} kg</td>
              <td class="text-center">{{ item.quant_dias_preparo }}</td>
              <td class="text-center">{{ item.quant_dias_entrega }}</td>
              <td class="text-center">
                {{ item.total_pedido_inicial | formatMoney }}
              </td>
              <td class="text-center">
                {{ item.total_pedido_final | formatMoney }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="11">
                Nenhum forma de entrega encontrada para a simulação
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import FormField from '@/components/form/FormField'
import FormSelect from '@/components/form/FormSelect'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import SelectCliente from '@/components/select/SelectCliente'
import LineDivider from '@/components/form/FormRadioGroup'
import { mapGetters } from 'vuex'
import {
  FORMASENTREGAS,
  FORMA_ENTREGA_SIMULAR,
  FORMA_ENTREGA_LIST
} from '@/store/actions/formasEntrega'

export default {
  name: 'FormaEntregaFormSimulacao',
  components: {
    SelectCliente,
    FormField,
    FormSelect,
    FormRadioGroup,
    LineDivider
  },
  data() {
    return {
      moneyMask: {
        decimal: '.',
        thousands: ' ',
        prefix: '',
        suffix: '',
        default: '',
        precision: 2,
        masked: false
      },
      formasEntrega: Object.keys(FORMASENTREGAS).map(chave => ({
        label: FORMASENTREGAS[chave],
        value: chave
      })),
      simulacaoFormasEntrega: {
        tipo: 'TRANSPORTE',
        formasEntrega: [],
        selecionadoTransporte: '',
        selecionadoCorreios: '',
        selecionadoFrenet: '',
        selecionadoBaseadaCliente: '',
        wasSearched: false
      },
      simulacao: [],
      formSimulacaoTransporte: {
        pesoTotal: '',
        precoTotal: '',
        cepDestino: ''
      },
      formSimulacaoCorreios: {
        produto: {
          altura: '',
          largura: '',
          comprimento: '',
          diametro: '',
          peso: '',
          preco: ''
        },
        metodoCalculo: 'SOMA_CUSTO_POR_PRODUTO',
        cepDestino: ''
      },
      formSimulacaoFrenet: {
        produto: {
          altura: '',
          largura: '',
          comprimento: '',
          diametro: '',
          peso: '',
          preco: ''
        },
        cepDestino: '',
        diasSeparacao: 1,
        acrescimoTaxa: ''
      },
      formSimulacaoBaseadaCliente: {
        pesoTotal: '',
        precoTotal: '',
        cepDestino: '',
        cliente: ''
      },
      isLoadedSimulacaoBaseadaCliente: false,
      clientes: []
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId']),
    formasEntregas() {
      return this.formasEntrega.filter(
        f => f.value != 'DELIVERY' && f.value != 'RETIRADA'
      )
    },
    endpointClient() {
      return `/api/v4/client/${this.getFornecedorId}/search/options?page=1&perpage=20`
    },
    formasEntregaTransporte() {
      return (this.simulacaoFormasEntrega.formasEntrega || [])
        .filter(f => f.tipo === 'TRANSPORTE')
        .map(f => {
          return { value: f.idformaentrega, text: f.nomeforma }
        })
    },
    formasEntregaCorreios() {
      return (this.simulacaoFormasEntrega.formasEntrega || [])
        .filter(f => f.tipo === 'CORREIOS')
        .map(f => {
          return { value: f.idformaentrega, text: f.nomeforma }
        })
    },
    formasEntregaFrenet() {
      return (this.simulacaoFormasEntrega.formasEntrega || [])
        .filter(f => f.tipo === 'FRENET')
        .map(f => {
          return { value: f.idformaentrega, text: f.nomeforma }
        })
    },
    formasEntregaBaseadaCliente() {
      return (this.simulacaoFormasEntrega.formasEntrega || [])
        .filter(f => f.tipo === 'BASEADA_CLIENTE')
        .map(f => {
          return { value: f.idformaentrega, text: f.nomeforma }
        })
    }
  },
  methods: {
    onSubmit() {
      const body = this.getDataSimulacao()
      if (!this.validarSubmissao()) return
      this.isLoading = true
      this.$store
        .dispatch(FORMA_ENTREGA_SIMULAR, body)
        .then(data => {
          this.simulacao = data || []
          this.isLoading = false
        })
        .catch(error => {
          this.simulacao = error || []
          this.isLoading = false
        })
    },
    getFormasEntrega() {
      this.isLoading = true
      this.$store
        .dispatch(FORMA_ENTREGA_LIST)
        .then(data => {
          this.simulacaoFormasEntrega.formasEntrega = data || []
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getDataSimulacao() {
      let form
      let idformaentrega
      switch (this.simulacaoFormasEntrega.tipo) {
        case 'TRANSPORTE':
          form = this.formSimulacaoTransporte
          idformaentrega = this.simulacaoFormasEntrega.selecionadoTransporte
          break
        case 'CORREIOS':
          form = this.formSimulacaoCorreios
          idformaentrega = this.simulacaoFormasEntrega.isSimulacaoCorreios
          break
        case 'FRENET':
          form = this.formSimulacaoFrenet
          idformaentrega = this.simulacaoFormasEntrega.selecionadoFrenet
          break
        case 'BASEADA_CLIENTE':
          this.formSimulacaoBaseadaCliente.idcliente = this.formSimulacaoBaseadaCliente.cliente.idcliente
          form = this.formSimulacaoBaseadaCliente
          idformaentrega = this.simulacaoFormasEntrega.selecionadoBaseadaCliente
          break
        default:
          form = null
          idformaentrega = null
      }
      return {
        tipo: this.simulacaoFormasEntrega.tipo,
        form,
        idformaentrega
      }
    },
    validarSubmissao() {
      const errors = []
      switch (this.simulacaoFormasEntrega.tipo) {
        case 'TRANSPORTE':
          if (
            !this.formSimulacaoTransporte.cepDestino ||
            !this.formSimulacaoTransporte.pesoTotal ||
            !this.formSimulacaoTransporte.precoTotal
          ) {
            errors.push('Todos os campos são obrigatórios')
          }
          break
        case 'CORREIOS':
          if (!this.formSimulacaoCorreios.cepDestino) {
            errors.push('CEP destino é obrigatório para Simulação de Correios')
          }
          break
        case 'FRENET':
          if (!this.formSimulacaoFrenet.cepDestino) {
            errors.push('CEP destino é obrigatório para Simulação de Frenet')
          }
          break
        case 'BASEADA_CLIENTE':
          if (!this.formSimulacaoBaseadaCliente.idcliente) {
            errors.push(
              'Cliente é obrigatório para Simulação Baseada no Cliente'
            )
          }
          if (!this.formSimulacaoBaseadaCliente.cepDestino) {
            errors.push(
              'CEP destino é obrigatório para Simulação Baseada no Cliente'
            )
          }
          break
        default:
          errors.push('Tipo inválido!')
      }
      if (errors.length > 0) {
        this.$vueOnToast.pop('error', errors.join(' - '))
        return false
      }
      return true
    }
  },
  watch: {},
  created() {
    this.getFormasEntrega()
    this.$on('clickSubmit', this.onSubmit)
  }
}
</script>
