<template>
  <v-card flat>
    <FormField label="Nome da Forma de Entrega" v-model="form.nomeforma" />
    <FormField label="Código ERP" v-model="form.erpTransportadora" />
    <FormFile
      v-if="form.idformaentrega == 'novo'"
      label="Arquivo"
      v-model="form.nameCurrentFile"
      @file="onSelectFile"
    >
      <template v-slot:help>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
          </template>
          Localize o arquivo de frete editado a partir do modelo disponível para
          importação
        </v-tooltip>
      </template>
    </FormFile>
    <FormSwitch
      v-model="form.permiteAgendamento"
      label="Permite agendamento"
      @click="form.idcalendarioentrega = null"
    />
    <FormSelect
      v-if="form.permiteAgendamento"
      label="Calendário"
      :options="calendarios"
      v-model="form.idcalendarioentrega"
    />
    <h4 v-if="form.idformaentrega == 'novo'">Enconding Arquivo</h4>
    <v-row no-gutters v-if="form.idformaentrega == 'novo'">
      <v-col>
        <FormRadioGroup v-model="form.encoding" inline :radios="encondings" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import FormField from '@/components/form/FormField'
import FormFile from '@/components/form/FormFile'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'
import FormRadioGroup from '@/components/form/FormRadioGroup'

export default {
  name: 'FormaEntregaFormTransporte',
  components: {
    FormField,
    FormFile,
    FormSwitch,
    FormSelect,
    FormRadioGroup
  },
  props: {
    calendarios: {
      type: [Array],
      default: () => ({}),
      required: true
    },
    data: {
      type: [Object],
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      form: {},
      encondings: [
        { label: 'UTF-8', value: 'utf8' },
        { label: 'ISO-8859-1', value: 'binary' }
      ]
    }
  },
  methods: {
    update() {
      this.$emit('update', this.form)
    },
    onSelectFile(file) {
      const size = 100 * 1024 * 1024
      if (file.size > size) {
        this.$vueOnToast.pop(
          'info',
          'Arquivo muito grande, pode levar alguns minutos para processar!'
        )
      }
      this.form.currentFile = file
      this.update()
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit('update', this.form)
      }
    }
  },
  created() {
    this.form = this.data
  }
}
</script>
